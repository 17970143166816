<template>
  <div component-provider>
    <component v-if="itemInfo.separate" :is="itemInfo.component" :structure="structure" :modelInfo="model" :info="itemInfo" v-model="model[itemInfo.key]" @input="v => $emit('update',v)" />
    <ValidationComponent v-else v-model="model[itemInfo.key]" :modelInfo="model" :structure="structure" :name="itemInfo.name" :component="itemInfo.component" :rules="itemInfo.rules" :preset="itemInfo.preset" :label="itemInfo.label" @input="v => $emit('update',v)" />
  </div>
</template>

<script>
import Specific from '@shared/types/Specific';
import ValidationComponent from '@/views/components/common/ValidationComponent.vue';

export default {
  name: 'ComponentProvider',
  components: { ValidationComponent },
  props: {
    itemInfo: { type: Specific, required: true },
    model: { type: Specific, required: true },
    structure: { type: Specific, required: true },
  },
  computed: {
    preset() {
      return this.itemInfo.preset || {};
    },
  },
  methods: {
    update(v) {

    }
  },
  mounted() {
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>