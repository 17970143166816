<template>
  <div step-title>
    <h1>{{ $t(title, {brandName}) }}</h1>
    <div v-if="!npOnly" class="step-list">
      <em v-for="(item,id) in stepList" :key="id" :class="{'active' : step >= id}">
        <FontIcon name="check" v-if="step > id" :default-path="cdnSvgPath"/>
        <template v-else>{{ id + 1 }}</template>
      </em>
    </div>
  </div>
</template>

<script>
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import Specific from '@shared/types/Specific';
import FontIcon from '@shared/components/common/FontIcon.vue';

export default {
  name: 'StepTitle',
  components: { FontIcon },
  props: {
    structure: { /** @type {ModelInfo} */type: Specific, default: null },
  },
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    npOnly: state('env', 'npOnly'),
    title() {
      return this.structure.list[this.step].title;
    },
    brandName() {
      return isExcludeGgpass(this.site) ? this.brand : 'GGPass';
    },
    step() {
      return Number(this.structure.step);
    },
    stepList() {
      return this.structure.list.slice(0, -1);
    }
  },
};
</script>

<style lang="less">
@import "~@/less/proj.less";
.RTL {
  [step-title] { .justify-end(); .rel();
    h1 { .tr(); .block(); .w(100%); }
    .step-list { .abs(); .lt(0,50%); .t-yc();  }
    @media (@ml-up) {
      h1 { }
    }
  }
}

.GOLD {
  [step-title] {
    .step-list {
      em.active { .bgc(@c-gold);}
    }
  }
}
[step-title] { .tl(); .m(8, 0, 48); .flex(); .space-between(); .items-center();
  h1 { .tl(); .regular(); .fs(24,32); .max-w(150); }
  .step-list { .flex(); .items-center();
    em { .wh(24); .br(50%); .c(white); .tc(); .fs(13, 24);.bgc(#717171); .rel(); .flex-center();
      &:not(:nth-of-type(1)) { .ml(40);
        &:before { .cnt(); .abs(); .wh(32, 1); .bgc(#717171); .lt(-36, 50%); .t-yc(); }
      }
      [font-icon] { .fs(14); .c(white); }
      &.active { .bgc(#c64040); }
    }
  }
  @media (@ml-up) {
    h1 { .max-w(none); .fs(28); }
    .step-list {
      em {
        &:not(:nth-of-type(1)) { .ml(56);
          &:before { .cnt(); .abs(); .wh(48, 1); .bgc(#717171); .lt(-52, 50%); .t-yc(); }
        }
      }
    }
  }
}
</style>
