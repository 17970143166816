<template>
  <div route-back>
    <a  @click="back">
      <FontIcon name="arrow-left"/>
    </a>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'RouteBack',
  components: { FontIcon },
  props: {
    hold: Boolean,
    structure: { /** @type {ModelInfo} */type: Specific, required: true },
  },
  computed: {

  },
  methods: {
    back() {
      this.$emit('back');
      if(this.hold) return;
      if(this.structure.step <= 0) this.$router.back();
      else this.replaceRouteName(this.structure.list[this.structure.step - 1].route, null, this.$route.query);
    }
  }
};
</script>

<style lang="less">
@import "~@/less/proj.less";
.RTL {
  [route-back] { .tr();
    >a { .t-sx(-1); .ib();  }
  }
}
[route-back] { .tl(); .mb(20);
  [font-icon] { .fs(20); }
}

</style>